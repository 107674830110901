import iaxios from "@/utils/iaxios";
// 测试地址
// const _baseUrl_ = 'https://data.capdata.com.cn';
// 生产地址
const _baseUrl_ = 'https://app.longfusi.com.cn';

// 图片地址
export const imgUrl =  _baseUrl_ + "/lfs-admin-server/upload";

//首页轮播图
export const getCarousel = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/carousel`,params: _data});
};
//店铺分类接口
export const getShopType = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/type/list`,params: _data});
};
//店铺分类接口
export const getSpecialShopsWeb = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/specialShopsWeb`,params: _data});
};
//活动列表接口
export const getActivity = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/activity/page`,params: _data});
};
//活动详情接口
export const getActivityDetail = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/activityDetail`,params: _data});
};

//首页管理
export const getFirstpageManage = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/firstpage`,params: _data});
};
//信息公开分页
export const getNewsPage = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/newsPage`,params: _data});
};
//信息公开详情
export const getNewsDetail = _data => {
	return iaxios({ url:`${_baseUrl_}/lfs-admin-server/front/newsDetail`,params: _data});
};
