const axios = require("axios");

const KEYS = ["status", "statusText", "data", "headers"];
function copyProperties(target, source, keys) {
  if (typeof source == "object" && Array.isArray(keys)) {
    keys.forEach(k => {
      if (typeof source[k] != "undefined") {
        target[k] = source[k];
      }
    });
  }
  return target;
}

async function iaxios(options) {
  var ret = {};
  try {
    var res = await axios(options);
    ret = copyProperties(ret, res, KEYS);
  } catch (e) {
    //请求已发出，但服务器状态码不是2**
    if (e.response) {
      ret = copyProperties(ret, e.response, KEYS);
    } else {
      var code = e.code || e.errno;
      var msg = e.message || e.toString();
      ret = {
        error: {
          code,
          msg,
        },
      };
    }
  }
  if(ret.data&&ret.data.error){
    ret.error={
      msg:ret.data.error
    }
  }
  if(ret.status==200){
    return ret.data;
  }
  return ret;
}
iaxios.stringify = function (obj) {
  var ret = "";
  for (var key in obj) {
    if (ret) {
      ret += "&";
    }
    ret += `${key}=${encodeURIComponent(obj[key])}`;
  }
  return ret;
};
export default iaxios;
