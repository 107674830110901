<template>
  <div class="activity_boxs">
    <header class="activity_head">
      <img src="@/assets/activity/activity.png" class="img_css" />
    </header>
    <main class="activity_main">
      <div class="main_title">
        <img src="@/assets/select_idx.png" class="item_icon" />
        <div class="item_title">市场活动</div>
        <div class="item_title1">ACTIVITIES</div>
      </div>
      <div class="activity_list">
        <div v-for="(item, index) in activityList" :key="index" class="item_list" @click="jumpChange(item)">
            <img :src="imgUrl+item.coverPicsList" class="item_img">
            <div class="item_text">
                <div class="item_title">{{ item.name }}</div>
                <div class="item_conent">{{item.introduction}}</div>
            </div>
        </div>
      </div>
      <div class="activity_foot">
<!--        <el-pagination layout="prev, pager, next" :total="100" class="page_boxs"/>-->
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref } from "vue";
// 跳路由
import router from "@/router/index";
import {getActivity,imgUrl} from '@/api/index';

let activityList = ref([
    // {
    //     img: require("@/assets/activity/1.png"),
    //     title: "意大利文艺复兴纸上绘画：一次与中国的对话",
    //     conent: "我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内...的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。"
    // },
    // {
    //     img: require("@/assets/activity/1.png"),
    //     title: "意大利文艺复兴纸上绘画：一次与中国的对话",
    //     conent: "我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内...的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。"
    // },
    // {
    //     img: require("@/assets/activity/1.png"),
    //     title: "意大利文艺复兴纸上绘画：一次与中国的对话",
    //     conent: "我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内...的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。"
    // },
    // {
    //     img: require("@/assets/activity/1.png"),
    //     title: "意大利文艺复兴纸上绘画：一次与中国的对话",
    //     conent: "我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内...的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。"
    // },
    // {
    //     img: require("@/assets/activity/1.png"),
    //     title: "意大利文艺复兴纸上绘画：一次与中国的对话",
    //     conent: "我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内...的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。"
    // },
    // {
    //     img: require("@/assets/activity/1.png"),
    //     title: "意大利文艺复兴纸上绘画：一次与中国的对话",
    //     conent: "我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内...的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。"
    // }
])

function jumpChange(item) {
  // console.log(item.id);
  router.push({
    path: "/Activity/detail",
    query:{
      id:item.id
    }
  })
}
const getActivityList = () => {
  getActivity({activityType:1}).then(res=>{
    if(res.code==1){
      // console.log(res.data.records);
      activityList.value=res.data.records
    }
  })
}
getActivityList()
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .activity_main{
    width: 1200px !important;
  }
  .item_list{
    height: 160px !important;
    .item_img {
      width: 260px !important;
      margin-right: 10px;
    }
  }
  .main_title{
    .item_title,.item_title1,{
      font-size: 28px !important;
    }
  }
  .item_text{
    .item_title{
      font-size: 20px !important;
    }
    .item_conent{
      font-size:16px !important;
      line-height:24px !important;
      margin-top: 5px !important;

    }
  }
}
@media only screen and (max-width: 1280px) {
  .activity_main{
    width: 950px !important;
  }
  .item_text{
    padding: 15px 36px 18px 9px !important;
  }
  .item_list{
    height: 120px !important;
    .item_img {
      width: 200px !important;
      //margin-right: 10px;
    }
  }
  .main_title{
    .item_title,.item_title1,{
      font-size: 20px !important;
    }
  }
  .item_text{
    .item_title{
      font-size: 16px !important;
    }
    .item_conent{
      font-size:12px !important;
      line-height:18px !important;
      margin-top: 5px !important;
    }
  }
}
@media only screen and (max-width: 960px) {
  .activity_main{
    width: 850px !important;
  }
}
@media only screen and (max-width: 768px) {
  .activity_main{
    width: 680px !important;
  }
  .main_title{
    .item_title,.item_title1,{
      font-size: 14px !important;
    }
  }
  .item_text{
    .item_title{
      font-size: 12px !important;
    }
    .item_conent{
      font-size:10px !important;
      line-height:18px !important;
      margin-top: 5px !important;
    }
  }
  .item_list{
    height: 100px !important;
    .item_img {
      width: 160px !important;
      //margin-right: 10px;
    }
  }
}
@media only screen and (max-width: 640px) {
  .activity_main{
    width: 560px !important;
  }
  .main_title{
    .item_title,.item_title1,{
      font-size: 12px !important;
    }
  }
  .item_text{
    .item_title{
      font-size: 10px !important;
    }
    .item_conent{
      font-size:8px !important;
      line-height:14px !important;
    }
  }
}
//@media only screen and (min-width: 2133px) {
//  .activity_main{
//    width: 1550px !important;
//  }
//}
//@media only screen and (min-width: 2400px) {
//  .activity_main{
//    width: 1750px !important;
//  }
//  .item_list{
//    height: 220px !important;
//    .item_img {
//      width: 340px !important;
//      margin-right: 10px;
//    }
//  }
//  .main_title{
//    .item_title,.item_title1,{
//      font-size: 38px !important;
//    }
//  }
//  .item_text{
//    .item_title{
//      font-size: 28px !important;
//    }
//    .item_conent{
//      font-size:22px !important;
//      line-height:36px !important;
//    }
//  }
//}
//@media only screen and (min-width: 2560px) {
//  .activity_main{
//    width: 1900px !important;
//  }
//  .item_list{
//    height: 240px !important;
//    .item_img {
//      width: 360px !important;
//      margin-right: 10px;
//    }
//  }
//  .main_title{
//    .item_title,.item_title1,{
//      font-size: 38px !important;
//    }
//  }
//  .item_text{
//    .item_title{
//      font-size: 32px !important;
//    }
//    .item_conent{
//      font-size:26px !important;
//      line-height:36px !important;
//    }
//  }
//}
//@media only screen and (min-width: 2880px) {
//  .activity_main{
//    width: 2150px !important;
//  }
//  .item_list{
//    height: 260px !important;
//    .item_img {
//      width: 400px !important;
//      margin-right: 10px;
//    }
//  }
//  .main_title{
//    .item_title,.item_title1,{
//      font-size: 48px !important;
//    }
//  }
//  .item_text{
//    .item_title{
//      font-size: 38px !important;
//    }
//    .item_conent{
//      font-size:30px !important;
//      line-height:50px !important;
//    }
//  }
//}
//@media only screen and (min-width: 3840px) {
//  .activity_main{
//    width: 2950px !important;
//  }
//  .item_list{
//    height: 360px !important;
//    .item_img {
//      width: 550px !important;
//      margin-right: 20px;
//    }
//  }
//  .main_title{
//    .item_title,.item_title1,{
//      font-size: 60px !important;
//    }
//  }
//  .item_text{
//    .item_title{
//      font-size: 50px !important;
//    }
//    .item_conent{
//      font-size:40px !important;
//      line-height:60px !important;
//    }
//  }
//}
//@media only screen and (min-width: 5760px) {
//  .activity_main{
//    width: 4450px !important;
//  }
//  .item_list{
//    height: 450px !important;
//    .item_img {
//      width: 700px !important;
//      margin-right: 20px;
//    }
//  }
//  .main_title{
//    .item_title,.item_title1,{
//      font-size: 70px !important;
//    }
//  }
//  .item_text{
//    .item_title{
//      font-size: 60px !important;
//    }
//    .item_conent{
//      font-size: 50px !important;
//      line-height:100px !important;
//    }
//  }
//}
//@media only screen and (min-width: 7680px) {
//  .activity_main{
//    width: 5900px !important;
//  }
//  .item_list{
//    height: 540px !important;
//    .item_img {
//      width: 800px !important;
//      margin-right: 20px;
//    }
//  }
//  .main_title{
//    .item_title,.item_title1,{
//      font-size: 70px !important;
//    }
//  }
//  .item_text{
//    .item_title{
//      font-size: 66px !important;
//    }
//    .item_conent{
//      font-size: 60px !important;
//      line-height:100px !important;
//    }
//  }
//}
</style>
<style lang="scss" scoped>
.activity_boxs {
  font-family: PingFang SC;
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  .img_css {
    width: 100%;
    display: flex;
  }

  .activity_head {
    // border: 30px solid #ffda00;
  }

  .activity_main {
    padding: 59px 0;
    width: 1400px;
    margin: 0 auto;
    .main_title {
      text-align: center;

      .item_icon {
        width: 41px;
        height: 28px;
      }

      .item_title {
        margin-top: 10px;
        font-size: 40px;
        font-weight: 400;
        color: #8c857b;
      }
      .item_title1 {
        font-size: 40px;
        font-family: Gotham;
        font-weight: 400;
        color: #eeeeee;
        margin-bottom: 49px;
      }
    }

    .activity_list {
        cursor: pointer;
        .item_list:hover{
          border-top: 4px solid #FFDA00;
        }
        .item_list {
            border-top: 4px solid #FFFFFF;
            height: 158px;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px 0px rgba(140,133,123,0.1);
            display: flex;
            margin-bottom: 20px;

            //&:first-child {
            //    border-top: 4px solid #FFDA00;
            //}

            .item_img {
                width: 280px;
                // height: 158px;
                position: relative;
                top: -4px;
            }

            .item_text {
                flex: 1;
                padding: 25px 46px 28px 19px;
                overflow: hidden;

                .item_title {
                    font-size: 22px;
                    font-weight: 400;
                    color: #111111;
                }

                .item_conent {
                    margin-top: 18px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #666666;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    line-height: 25px;
                    font-family: PingFangSC-Light;
                }
            }
        }
    }

    .activity_foot {
        .page_boxs {
            justify-content: center;

        }
    }
  }
}
</style>
